import { cast, flow, getEnv, getParent, types } from 'mobx-state-tree';
import { getChargingStationType } from 'src/core';

import { ChargingStationsApi } from '../api';
import {
  ChargingStationConnectorStatus,
  ChargingStationLog,
  ChargingStationSearchResult,
  ChargingProfile,
  ConnectorStatus,
  MeterValue,
  MeterValueFunction,
  MeterValueTimeWindow,
  MeterValueType,
  OccpSearchResult,
  Transaction,
  TransactionSearchResult,
  ChargingStationConnectionStatus,
  BlockStatus,
  BlocklistItem,
  ChargingStationReservation,
  FactoryKey,
  FreeOfChargeSchedule,
  FreeOfChargeRecurrencyKind,
  ChargingStationSettings,
  ChargingStationSearchItem,
} from './model';
import { ChargingStation, ChargingStationType } from './model/ChargingStation';
import { RootModel } from './RootStore';
import { addSeconds } from 'date-fns';

export const ChargingStationStore = types
  .model({
    chargingStationSearchItems: types.maybeNull(ChargingStationSearchResult),
    locationChargingStationSearchItems: types.maybeNull(ChargingStationSearchResult),
    connectorStatusItems: types.map(
      types.model({
        chargingStationId: types.identifier,
        connectors: types.map(ChargingStationConnectorStatus)
      })
    ),
    ocppSearchItems: types.maybeNull(OccpSearchResult),
    transactionSearchItems: types.maybeNull(TransactionSearchResult),
    chargingStationLogItems: types.map(ChargingStationLog),
    chargingStationReservationItems: types.map(ChargingStationReservation),
    blockListItems: types.map(BlocklistItem),
    factoryKeys: types.map(FactoryKey),
    chargingProfiles: types.map(ChargingProfile),
    chargingStationSettingsItems: types.map(ChargingStationSettings),
  })
  .volatile(() => ({
    isLoading: true,
    isLoadingAuth: true,
    currentChargingStationId: '',

    // grid cache
    searchString: '',
    searchFilter: 'location_name',
    loadingBatch: false,
    previousNext: [],
    rowsPerPage: 25,
    page: 0,
    persistedPagination: false
  }))
  .views((self) => ({
    get api(): ChargingStationsApi {
      return getEnv(self).chargingStationsApi;
    },
    get chargingStationSearchResult() {
      return Array.from(self.chargingStationSearchItems?.items.values() ?? []);
    },
    get locationChargingStationSearchResult() {
      return Array.from(self.locationChargingStationSearchItems?.items.values() ?? []);
    },
    get currentChargingStation() {
      return this.chargingStationSearchResult?.find(
        (cs) => cs.charging_station?.id === self.currentChargingStationId
      )?.charging_station;
    },
    get currentChargingStationStatus() {
      return this.chargingStationSearchResult?.find(
        (cs) => cs.charging_station?.id === self.currentChargingStationId
      )?.charging_station?.status;
    },
    get isChargingStationOnline(): (id: string) => boolean {
      const isOnline = (id: string) => {
        return (this.chargingStationSearchResult?.find(
          (cs) => cs.charging_station?.id === id
        )?.charging_station?.status?.connection === ChargingStationConnectionStatus.connected);
      };
      return isOnline;
    },
    get chargingStationSearchItemsCount() {
      return self.chargingStationSearchItems?.total ?? 0;
    },
    get chargingStationSearchItemsNext() {
      return self.chargingStationSearchItems?.next;
    },
    get currentChargingStationType(): ChargingStationType {
      const uid = this.currentChargingStation?.uid ?? '';
      return getChargingStationType(uid);
    },
    get chargingStationType(): (
      chargingStationId: string,
    ) => ChargingStationType {
      return (chargingStationId: string) => {
        const chargingStation = (this.chargingStationSearchResult?.find(
          (cs) => cs.charging_station?.id === chargingStationId
        )?.charging_station);
        const uid = chargingStation?.uid ?? '';
        return getChargingStationType(uid);
      };
    },
    get currentContext(): string {
      return 'Installer';
    },
    get currentLocationId(): string {
      return (
        getParent<typeof RootModel>(self).locationStore.currentLocationId || ''
      );
    },
    get allLocationIds(): string[] {
      return getParent<typeof RootModel>(self).locationStore.locations.map(
        (loc) => `${loc.id}`
      );
    },
    get userVirtualIdToken(): string {
      return (
        getParent<typeof RootModel>(self).userStore.user?.virtual_id_token ?? ''
      );
    },
    get currentChargingStationOccpLog() {
      return Array.from(self.ocppSearchItems?.items.values() || []);
    },
    get getUser(): (id: string) => Promise<void> {
      return getParent<typeof RootModel>(self).userStore.getUser;
    },
    get currentChargingStationConnectors() {
      return Array.from(
        this.chargingStationSearchResult
          ?.find(
            (cs) => cs.charging_station?.id === self.currentChargingStationId
          )
          ?.charging_station?.connectors_statuses?.values() || []
      );
    },
    get isCurrentChargingStationAvailable(): boolean {
      return (
        this.chargingStationConnectorMainStatus(
          self.currentChargingStationId
        ) === ConnectorStatus.Available
      );
    },
    get currentChargingStationLocationName() {
      return this.chargingStationSearchResult?.find(
        (cs) => cs.charging_station?.id === self.currentChargingStationId
      )?.location_name;
    },
    get isCurrentChargingStationFreeOfCharge(): boolean {
      return !!self.chargingStationSettingsItems.get(
        self.currentChargingStationId,
      )?.free_of_charge;
    },
    get isCurrentChargingStationAutomaticFirmwareUpdate(): boolean {
      return !!self.chargingStationSettingsItems.get(
        self.currentChargingStationId,
      )?.update_firmware_automatically;
    },
    get isCurrentChargingStationSyncOnBoot(): boolean {
      return !!this.currentChargingStation?.settings
        ?.sync_configuration_always_on_boot;
    },
    get isCurrentChargingStationIgnoreOnSync(): boolean {
      return !!this.currentChargingStation?.settings
        ?.ignore_unknown_keys_on_sync;
    },
    get isCurrentChargingStationSyncAsBestEffort(): boolean {
      return !!this.currentChargingStation?.settings
        ?.sync_configuration_as_best_effort;
    },
    get chargingStationConnectorMainStatus(): (id: string) => ConnectorStatus {
      const getStatus = () => {
        const status =
          this.currentChargingStation?.connectors_statuses?.at(0)?.status ??
          ConnectorStatus.Unavailable;
        return status;
      };
      return getStatus;
    },
    get chargingStationLogFiles(): (id: string) => ChargingStationLog[] {
      const getLogFiles = (chargingStationId: string) => {
        return Array.from(self.chargingStationLogItems.values() || []).filter(
          (item) => item.charging_station_id === chargingStationId
        );
      };

      return getLogFiles;
    },
    get chargingStationLatestTransaction(): (
      id: string,
      connectorId: number,
      started?: boolean
    ) => Transaction | undefined {
      const getlatestTransaction = (
        chargingStationId: string,
        connector_id: number,
        started = true
      ) => {
        return self.transactionSearchItems?.items.find((transaction) =>
          transaction.charging_station_id === chargingStationId &&
            transaction.connector_id === connector_id &&
            started
            ? transaction.state === 'Started'
            : true
        );
      };
      return getlatestTransaction;
    },
    get currentChargingStationTransactions() {
      return (
        self.transactionSearchItems?.items
          .filter(
            (item) => item.charging_station_id === self.currentChargingStationId
          )
          .sort((a, b) => (a.start_time < b.start_time ? 1 : -1)) || []
      );
    },
    get currentChargingStationReservations() {
      return Array.from(self.chargingStationReservationItems.values() || []).filter(
        (item) => item.charging_station_id === self.currentChargingStationId
      );
    },
    get chargingStationOwners() {
      return Array.from(
        this.chargingStationSearchResult
          ?.find(
            (cs) => cs.charging_station?.id === self.currentChargingStationId
          )
          ?.owner_emails?.values() || []
      );
    },
    get groupKey() {
      const uid = this.currentChargingStation?.uid;
      return Array.from(self.factoryKeys.values() || []).find(
        (fk) => fk.id === uid
      )?.group_key;
    },
    get currentChargingStationProfiles() {
      return Array.from(self.chargingProfiles.values() || []).filter(
        (item) => item.charging_station_id === self.currentChargingStationId
      );
    },
  }))
  .actions(self => {
    const setPersistedPagination = (persist: boolean) => {
      self.persistedPagination = persist;
    };

    const setPage = (page: number) => {
      self.page = page;
    };

    const setRowsPerPage = (rowsPerPage: number) => {
      self.rowsPerPage = rowsPerPage;
    };

    const setPreviousNext = (previousNext: any) => {
      self.previousNext = previousNext;
    };

    const setSearchString = (searchString: string) => {
      self.searchString = searchString;
    }

    const setSearchFilter = (searchFilter: string) => {
      self.searchFilter = searchFilter;
    }

    const deleteChargingProfile = (chargingProfileId: string) => {
      self.chargingProfiles.delete(chargingProfileId);
    };
    
    const addChargingStationSettings = (
      id: string,
      cs: ChargingStationSettings,
    ) => {
      if (id && cs) {
        self.chargingStationSettingsItems.set(id, cast(cs));
      }
    };
    
    const resetPagination = () => {
      self.persistedPagination = false;
      self.page = 0;
      self.rowsPerPage = 25;
      self.previousNext = [];
      self.searchString = '';
      self.searchFilter = '';
    };

    const markLoading = (loading: boolean) => {
      self.isLoading = loading;
    };

    const addChargingStationSearchItems = (
      result: ChargingStationSearchResult
    ) => {
      self.chargingStationSearchItems = cast(result);
    };

    const addLocationChargingStationSearchItems = (
      result: ChargingStationSearchResult
    ) => {
      self.locationChargingStationSearchItems = cast(result);
    };

    const addChargingStationItem = (
      charging_station: ChargingStation
    ) => {
      if (self.chargingStationSearchItems) {
        const index = self.chargingStationSearchItems.items.findIndex(
          (item) => item.charging_station?.id === charging_station.id
        );

        if (index !== -1) {
          const item = JSON.stringify({ ...self.chargingStationSearchItems.items.at(index) });
          self.chargingStationSearchItems.items.splice(index, 1, { ...JSON.parse(item), charging_station });
        }
      }
    };

    const addChargingStationSearchItem = (
      charging_station: ChargingStationSearchItem
    ) => {
      if (self.chargingStationSearchItems) {
        const index = self.chargingStationSearchItems.items.findIndex(
          (item) => item.charging_station?.id === charging_station.charging_station?.id
        );

        if (index !== -1) {
          addChargingStationItem(charging_station);
        } else {
          self.chargingStationSearchItems.items.push(charging_station);
        }
      }
    };

    const addChargingStationLog = (log: ChargingStationLog) => {
      self.chargingStationLogItems.set(String(log.id), cast(log));
    };

    const addBlockListItem = (blockItem: BlocklistItem) => {
      self.blockListItems.set(blockItem.id, cast(blockItem));
    };

    const addReservationListItem = (reservation: ChargingStationReservation) => {
      if (!reservation.id) {
        return;
      }
      self.chargingStationReservationItems.set(reservation.id, cast(reservation));
    };

    const addOcppSearchResult = (result: OccpSearchResult) => {
      if (
        !self.ocppSearchItems?.items.length ||
        result?.items?.[0]?.charging_station_id !== self.currentChargingStationId
      ) {
        self.ocppSearchItems = cast(result);
      }
      if (self.ocppSearchItems && result?.items?.length) {
        const items = [...self.ocppSearchItems.items, ...result.items].filter(
          (transaction, index, s) =>
            index === s.findIndex((t) => t?.id === transaction?.id)
        );

        self.ocppSearchItems = cast({
          ...result,
          items
        });
      }
    };

    const addTransactionSearchResult = (result: TransactionSearchResult) => {
      if (
        !self.transactionSearchItems?.items.length ||
        result?.items?.[0]?.charging_station_id !== self.currentChargingStationId
      ) {
        self.transactionSearchItems = cast(result);
      } else if (self.transactionSearchItems && result?.items?.length) {
        const items = [...self.transactionSearchItems.items, ...result.items]
          .reverse()
          .filter(
            (transaction, index, s) =>
              index === s.findIndex((t) => t?.id === transaction?.id)
          )
          .sort((a, b) => (a.start_time < b.start_time ? 1 : -1));

        self.transactionSearchItems = cast({
          ...result,
          items
        });
      }
    };

    const addChargingStationConnectorStatus = (
      connector: ChargingStationConnectorStatus
    ) => {
      if (!self.connectorStatusItems.has(connector.charging_station_id)) {
        self.connectorStatusItems.set(connector.charging_station_id, {
          chargingStationId: connector.charging_station_id
        });
      }

      self.connectorStatusItems
        .get(connector.charging_station_id)
        ?.connectors.set(`${connector.connector_id}`, cast(connector));
    };

    const clearChargingStations = () => {
      markLoading(true);
      self.currentChargingStationId = '';
      self.chargingStationSearchItems?.items.clear();
    };

    const clearLocationChargingStations = () => {
      markLoading(true);
      self.locationChargingStationSearchItems?.items.clear();
    };

    const addFactoryKey = (factoryKey: FactoryKey) => {
      self.factoryKeys.set(factoryKey.id, cast(factoryKey));
    };

    const deleteChargingStation = (cs: ChargingStation) => {
      self.chargingStationSearchItems?.items.splice(
        self.chargingStationSearchItems.items.findIndex(
          (item) => item.charging_station?.id === cs.id
        ),
        1
      );
    };

    const getChargingStations = flow(function* (
      filterType = '',
      searchString = ' ',
      pageSize = 25,
      next = '',

    ) {
      markLoading(true);
      const { data, error } = yield self.api.getChargingStations(
        filterType,
        searchString !== '' ? searchString : ' ',
        pageSize,
        next
      );
      if (!error) {
        addChargingStationSearchItems(data);
      }

      markLoading(false);
    });

    const getChargingStationsForLocation = flow(function* (
      filterType = '',
      searchString = '',
      pageSize = 25,
      next = '',

    ) {
      markLoading(true);
      const { data, error } = yield self.api.getChargingStations(
        searchString.length ? filterType : '',
        searchString,
        pageSize,
        next
      );
      if (!error) {
        addLocationChargingStationSearchItems(data);
        const items: any[] = Array.from(data.items);
        items.forEach((item) => {
          addChargingStationSearchItem(item);
        });
      }

      markLoading(false);
    });

    const getChargingStationConnectorStatus = flow(function* (
      chargingStationId: string
    ) {
      const { data, error } = yield self.api.getChargingStationConnectorStatus(
        chargingStationId
      );

      if (!error && data) {
        data.forEach((connector: ChargingStationConnectorStatus) =>
          addChargingStationConnectorStatus(connector)
        );
      }
    });

    const getChargingStationTransactions = flow(function* (
      chargingStationId: string,
      takeNext = false
    ) {
      const next = !takeNext ? undefined : self.transactionSearchItems?.next;

      const { data, error } = yield self.api.getOcppTransactions(
        chargingStationId,
        self.currentContext as any,
        undefined,
        next
      );

      if (!error && data) {
        addTransactionSearchResult(data);
      }
      return (data as TransactionSearchResult)?.items;
    });

    const getCurrentChargingStationOcppEvents = flow(function* (
      takeNext = false
    ) {
      const { data, error } = yield self.api.getOcppEvents(
        self.currentChargingStationId,
        takeNext ? self.ocppSearchItems?.next : undefined
      );

      self.ocppSearchItems = null; // "resetting" results

      if (!error && data) {
        addOcppSearchResult(data);
      }
    });

    const getChargingStationOcppEvents = flow(function* (
      chargingStationId: string,
      type: string,
      takeNext = false
    ) {
      const { data, error } = yield self.api.getOcppEvents(
        chargingStationId,
        takeNext ? self.ocppSearchItems?.next : undefined,
        type
      );

      if (!error && data) {
        addOcppSearchResult(data);
      }
    });

    const getChargingStationHistoricalMeterValues = flow(function* (
      chargingStationId: string,
      time_window?: MeterValueTimeWindow,
      measurand?: MeterValueType,
      functionType?: MeterValueFunction
    ) {
      const { data } = yield self.api.getChargingStationHistoricalMeterValues(
        chargingStationId,
        self.currentContext as any,
        time_window,
        measurand,
        functionType
      );

      return data as MeterValue[];
    });

    const getChargingStationLatestMeterValues = flow(function* (
      chargingStationId: string,
      connectorId?: number,
      transactionId?: string
    ) {
      const { data, error } =
        yield self.api.getChargingStationLatestMeterValues(
          chargingStationId,
          connectorId,
          transactionId
        );

      if (!error && data) {
        return data;
      }
    });

    const getChargingStationReservation = flow(function* (
      chargingStationId: string,
    ) {
      const { data, error } = yield self.api.getChargingStationReservations(
        chargingStationId,
      );

      if (!error && data) {
        addReservationListItem(data);
      }
    });

    const deleteChargingStationReservation = flow(function* (
      reservationId: string,
    ) {
      const { error } = yield self.api.deleteChargingStationReservation(
        reservationId,
      );

      if (!error) {
        self.chargingStationReservationItems.delete(reservationId);
      }
    });

    const getChargingUnitBlocklist = flow(function* (chargingUnitId: string) {
      const { data, error } = yield self.api.getChargingStationBlocklist(chargingUnitId);

      if (!error && data) {
        addBlockListItem(data);
      }
    });

    const updateBlocklistItem = flow(function* (charginUnitId: string, status: BlockStatus) {
      if (self.blockListItems.has(charginUnitId)) {
        const { data, error } = yield self.api.updateChargingStationBlocklist(
          charginUnitId,
          status
        );

        if (!error && data) {
          addBlockListItem(data);
        }
      } else {
        const { data, error } = yield self.api.createChargingStationBlocklist(
          charginUnitId,
          status
        );

        if (!error && data) {
          addBlockListItem(data);
        }
      }
    });

    const getChargingStationLogs = flow(function* (chargingStationId: string) {
      const { data, error } = yield self.api.getChargingStationLogs(
        chargingStationId
      );

      if (!error && data) {
        data?.forEach((log: ChargingStationLog) => {
          addChargingStationLog(log);
        });
      }
    });

    const getChargingStation = flow(function* (id: string) {
      const { data, error } = yield self.api.getChargingStation(id);

      if (!error && data) {
        addChargingStationItem(data);
      }
    });

    const createChargingStation = flow(function* (
      chargingStation: ChargingStation
    ) {
      const { data, error } = yield self.api.addChargingStation(
        chargingStation,
        self.currentLocationId
      );

      if (!error && data) {
        addChargingStationItem(data);
      }
    });

    const updateChargingStation = flow(function* (
      id: string,
      name?: string,
      newSerial?: string
    ) {
      if (name || (newSerial && newSerial !== id)) {
        const currentChargingStation =
          self.chargingStationSearchItems?.items.find(
            (cs) => cs.charging_station?.id === id
          )?.charging_station;
        const { data, error } = yield self.api.updateChargingStation(
          {
            ...currentChargingStation,
            name: name || currentChargingStation?.name || ''
          },
          newSerial
        );
        if (!error && data) {
          if (newSerial) {
            setCurrentChargingStation(newSerial);
            self.chargingStationSearchItems?.items.splice(
              self.chargingStationSearchItems?.items.findIndex(
                (cs) => cs.charging_station?.id === id
              ),
              1,
              data
            );
          } else {
            addChargingStationItem(data);
          }
        }
      }
    });

    const removeChargingStation = flow(function* (
      chargingStation: ChargingStation
    ) {

      if (chargingStation.id) {
        const { error } = yield self.api.removeChargingStation(
          chargingStation.id
        );

        if (!error) {
          deleteChargingStation(chargingStation);
          // @note handled by CSMS (If deleted charging station is part of a connection group, remove from connection group aswell.)
        }
      }
    });

    const setCurrentChargingStation = (id: string) => {
      self.currentChargingStationId = id;
    };

    const getFactoryKeys = flow(function* (
      chargingStationUId: string,
    ) {
      const { data, error } = yield self.api.getFactoryKeys(
        chargingStationUId,
      );
      if (!error) {
        addFactoryKey(FactoryKey.create({ id: chargingStationUId, group_key: 'Not found' }));
        Object.keys(data).forEach((key) => {
          addFactoryKey(data[key]);
        });
      }
    });

    const addChargingProfile = (profile: ChargingProfile) => {
      try {
        self.chargingProfiles.set(String(profile.charging_profile_id), cast(profile));
      } catch (error: any) {
        console.error('Error adding charging profile', error.message);
      }
    };

    const getChargingProfiles = flow(function* (chargingStationId: string) {
      markLoading(true);
      const { data, error } = yield self.api.getChargingProfiles(
        chargingStationId,
      );
      if (!error && data) {
        self.chargingProfiles.clear();
        data.forEach((chargingProfile: ChargingProfile) => {
          // Only add TxDefaultProfiles
          if (chargingProfile.charging_profile_purpose !== 'TxProfile') {
            addChargingProfile(chargingProfile);
          }
        });
      }

      markLoading(false);
      return data as ChargingProfile[];
    });

    const updateChargingProfile = flow(function* (
      chargingProfile: ChargingProfile,
      updateWithAction = true,
    ) {
      markLoading(true);
      const { data, error } = yield self.api.updateChargingProfiles(
        chargingProfile.charging_station_id,
        [{ ...chargingProfile }],
      );
      if (!error) {
        data.forEach((updatedProfile: ChargingProfile) => {
          addChargingProfile(updatedProfile);

          if (updatedProfile.stack_level === 1) {
            keepFreeOfChargeSchedulesUpdated(
              chargingProfile.charging_station_id,
              updatedProfile,
            );
          }
        });

        const {
          charging_station_id: _,
          active: __,
          connector_id: ___,
          ...ocppChargingProfile
        } = chargingProfile;

        // Update profile in charging station if online
        if (updateWithAction) {
          getParent<typeof RootModel>(self).actionStore.setChargingProfile(
            chargingProfile.charging_station_id,
            chargingProfile.connector_id ?? 1,
            ocppChargingProfile,
          );
        }
      }
      markLoading(false);
    });

    const removeChargingProfile = flow(function* (
      charging_station_id: string,
      charging_profile_id: number,
    ) {
      if (charging_station_id && charging_profile_id) {
        markLoading(true);
        const { error } = yield self.api.deleteChargingProfiles(
          charging_station_id,
          [charging_profile_id as any],
        );

        if (!error) {
          deleteChargingProfile(String(charging_profile_id));

          // Update profile in charging station if online
          getParent<typeof RootModel>(self).actionStore.clearChargingProfile(
            charging_station_id,
            charging_profile_id,
            1,
          );
        }
        markLoading(false);
      }
    });

    const keepFreeOfChargeSchedulesUpdated = flow(function* (
      chargingStationId: string,
      chargingProfile: ChargingProfile,
    ) {
      const { data } = yield self.api.getFreeOfChargeSchedules(
        chargingStationId,
      );

      const removePromises: Promise<any>[] = data?.map(
        (freeOfChargeSchedule: FreeOfChargeSchedule) =>
          self.api.deleteFreeOfChargeSchedules(
            chargingStationId,
            freeOfChargeSchedule.id ?? '',
          ),
      );

      yield Promise.all(removePromises);

      const createPromises =
        chargingProfile.charging_schedule?.charging_schedule_item
          ?.filter(
            (item) =>
              (item.type === 'FreeChargeByApp' && item.active) ||
              (item.type === 'FreeCharge' &&
                item.active &&
                chargingProfile.active),
          )
          .map((item) =>
            self.api.createFreeOfChargeSchedules(chargingStationId, {
              charging_station_id: chargingStationId,
              active: true,
              start_time: addSeconds(
                new Date(chargingProfile.charging_schedule.start_schedule ?? 0),
                item.start_period,
              ).toISOString(),
              end_time: addSeconds(
                new Date(chargingProfile.charging_schedule.start_schedule ?? 0),
                item.stop_period,
              ).toISOString(),
              recurring: FreeOfChargeRecurrencyKind.Weekly,
            } as FreeOfChargeSchedule),
          );

      if (createPromises && createPromises.length > 0) {
        yield Promise.all(createPromises);
        setFreeOfCharge(chargingStationId, true);
      } else {
        setFreeOfCharge(chargingStationId, false);
      }
    });

    const setFreeOfCharge = flow(function* (
      chargingStationId: string,
      enabled: boolean,
    ) {
      if (chargingStationId) {
        const updatedSettings = self.chargingStationSettingsItems.get(chargingStationId);
        if (updatedSettings) {
          updatedSettings.free_of_charge = enabled;
          const { error } = yield self.api.updateChargingStationSettings(
            chargingStationId,
            updatedSettings,
          );

          if (!error) {
            addChargingStationSettings(chargingStationId, updatedSettings);
          }
        }
      }
    });

    const getCurrentChargingStationSettings = flow(function* () {
      if (self.currentChargingStationId) {
        const { data, error } = yield self.api.getChargingStationSettings(
          self.currentChargingStationId,
        );

        if (!error && data) {
          addChargingStationSettings(self.currentChargingStationId, data);
        }
      }
    });

    const toggleSyncOnBoot = flow(function* () {
      if (self.currentChargingStationId) {
        const updatedSettings = self.chargingStationSettingsItems.get(
          self.currentChargingStationId,
        );
        if (updatedSettings) {
          updatedSettings.sync_configuration_always_on_boot =
            !updatedSettings.sync_configuration_always_on_boot;
          const { error } = yield self.api.updateChargingStationSettings(
            self.currentChargingStationId,
            updatedSettings,
          );

          if (!error) {
            addChargingStationSettings(
              self.currentChargingStationId,
              updatedSettings,
            );
          }
        }
      }
    });

    const toggleIgnoreKeysOnSync = flow(function* () {
      if (self.currentChargingStationId) {
        const updatedSettings = self.chargingStationSettingsItems.get(
          self.currentChargingStationId,
        );
        if (updatedSettings) {
          updatedSettings.ignore_unknown_keys_on_sync =
            !updatedSettings.ignore_unknown_keys_on_sync;
          const { error } = yield self.api.updateChargingStationSettings(
            self.currentChargingStationId,
            updatedSettings,
          );

          if (!error) {
            addChargingStationSettings(
              self.currentChargingStationId,
              updatedSettings,
            );
          }
        }
      }
    });

    const toggleAutomaticFirmwareUpdate = flow(function* () {
      if (self.currentChargingStationId) {
        const updatedSettings = self.chargingStationSettingsItems.get(
          self.currentChargingStationId,
        );
        if (updatedSettings) {
          updatedSettings.update_firmware_automatically =
            !updatedSettings.update_firmware_automatically;
          const { error } = yield self.api.updateChargingStationSettings(
            self.currentChargingStationId,
            updatedSettings,
          );

          if (!error) {
            addChargingStationSettings(
              self.currentChargingStationId,
              updatedSettings,
            );
          }
        }
      }
    });

    const toggleSyncAsBesteffort = flow(function* () {
      if (self.currentChargingStationId) {
        const updatedSettings = self.chargingStationSettingsItems.get(
          self.currentChargingStationId,
        );
        if (updatedSettings) {
          updatedSettings.sync_configuration_as_best_effort =
            !updatedSettings.sync_configuration_as_best_effort;
          const { error } = yield self.api.updateChargingStationSettings(
            self.currentChargingStationId,
            updatedSettings,
          );

          if (!error) {
            addChargingStationSettings(
              self.currentChargingStationId,
              updatedSettings,
            );
          }
        }
      }
    });

    return {
      getChargingStationTransactions,
      getChargingStationOcppEvents,
      getChargingStationConnectorStatus,
      clearChargingStations,
      clearLocationChargingStations,
      getChargingStations,
      getChargingStationsForLocation,
      getChargingStation,
      getCurrentChargingStationOcppEvents,
      getChargingStationLogs,
      getChargingStationLatestMeterValues,
      getChargingStationHistoricalMeterValues,
      createChargingStation,
      updateChargingStation,
      removeChargingStation,
      setCurrentChargingStation,
      getChargingUnitBlocklist,
      updateBlocklistItem,
      getChargingStationReservation,
      deleteChargingStationReservation,

      setPage,
      setRowsPerPage,
      setPreviousNext,
      setSearchString,
      setSearchFilter,
      setPersistedPagination,
      resetPagination,
      getFactoryKeys,
      getChargingProfiles,
      updateChargingProfile,
      removeChargingProfile,
      setFreeOfCharge,
      getCurrentChargingStationSettings,
      toggleSyncOnBoot,
      toggleIgnoreKeysOnSync,
      toggleAutomaticFirmwareUpdate,
      toggleSyncAsBesteffort,
    };
  });
